import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  DialogContentText,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';

const PagarConta = ({
  modalPagar,
  setModalPagar,
  idConta,
  pagoDia,
  setPagoDia,
  formaPag,
  setFormaPag,
  pagarConta,
  fullScreen,
  desabilitar,
}) => {
  return (
    <>
      {/* pagamento de conta */}
      <Dialog
        fullScreen={fullScreen}
        open={modalPagar}
        onClose={() => setModalPagar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Estornar conta'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que deseja pagar conta Nº {idConta} ?
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Data do pagamento"
                value={pagoDia}
                onChange={e => setPagoDia(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Forma de pagamento"
                value={formaPag}
                onChange={e => setFormaPag(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                <MenuItem value="dinh">DINHEIRO</MenuItem>
                <MenuItem value="cheq">CHEQUE</MenuItem>
                <MenuItem value="cartC">CARTÃO DE CRÉDITO</MenuItem>
                <MenuItem value="cartD">CARTÃO DE DÉBITO</MenuItem>
                <MenuItem value="bole">BOLETO</MenuItem>
                <MenuItem value="tranf">TRANSFERÊNCIA</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={pagarConta}
            disabled={desabilitar}
            variant="contained"
            color="primary"
          >
            Sim
          </Button>
          <Button
            variant="contained"
            disabled={desabilitar}
            color="secondary"
            onClick={() => setModalPagar(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PagarConta;
