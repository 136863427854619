import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  select: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
  },
}));
