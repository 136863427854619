import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
  },
  grid: {
    marginTop: '70px',
  },
}));
