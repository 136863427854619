import React, { useState, useEffect } from 'react';
import { usePermissoes } from '../../../hooks/permissoes';
import { useConfig } from '../../../hooks/config';
import axios from 'axios';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import {
  IconButton,
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { mask, unMask } from 'remask';
import SearchIcon from '@material-ui/icons/Search';

const EditarCadastrarPaciente = ({
  paciente,
  modalEditarCadastrar,
  setModalEditarCadastrar,
  convenios,
  abrirAviso,
  classes,
  pacientes,
  setPacientes,
}) => {
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const [desabilitar, setDesabilitar] = useState(false);
  const [idPaciente, setIdPaciente] = useState('');
  const [cpf, setCpf] = useState('');
  const [vCpf, setVCpf] = useState('');
  const [rg, setRg] = useState('');
  const [nome, setNome] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [sexo, setSexo] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [nacionalidade, setNacionalidade] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [nCartao, setNumeroCartao] = useState('');
  const [convenio, setConvenio] = useState('');
  const [login, setLogin] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep2, setCep2] = useState('');
  const [logradouro2, setLogradouro2] = useState('');
  const [numero2, setNumero2] = useState('');
  const [bairro2, setBairro2] = useState('');
  const [complemento2, setComplemento2] = useState('');
  const [cidade2, setCidade2] = useState('');
  const [estado2, setEstado2] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const abrirDados = () => {
    setIdPaciente(paciente.id);
    setCpf(paciente.cpf);
    setVCpf(paciente.cpf);
    setRg(paciente.rg);
    setNome(paciente.nome);
    setDataNasc(paciente.dataNasc);
    setSexo(paciente.sexo);
    setNaturalidade(paciente.naturalidade);
    setNacionalidade(paciente.nacionalidade);
    setEstadoCivil(paciente.estadoCivil);
    setProfissao(paciente.profissao);
    setTelefone(paciente.telefone);
    setNumeroCartao(paciente.nCartao);
    setConvenio(paciente.convenio);
    setCelular(paciente.celular);
    setCep(paciente.endCepR);
    setLogradouro(paciente.endLograR);
    setNumero(paciente.endNumR);
    setBairro(paciente.endBairroR);
    setCidade(paciente.endCidadeR);
    setEstado(paciente.endEstadoR);
    setComplemento(paciente.endCompleR);
    setCep2(paciente.endCepP);
    setLogradouro2(paciente.endLograP);
    setNumero2(paciente.endNumP);
    setLogin(paciente.login);
    setBairro2(paciente.endBairroP);
    setCidade2(paciente.endCidadeP);
    setEstado2(paciente.endEstadoP);
    setComplemento2(paciente.endCompleP);
  };

  const verificarCpf = async cpf => {
    let ret = false;
    await api
      .get('Usuario/verificarCpf.php?pdo=' + getClinica() + '&cpf=' + cpf)
      .then(res => {
        ret = res.data;
      });
    return ret;
  };

  const validarCampos = () => {
    if (cpf && rg && nome && dataNasc && sexo && profissao) {
      return true;
    } else {
      return false;
    }
  };

  const salvar = () => {
    paciente !== null ? editar() : cadastrar();
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.pacCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let existeCPF = await verificarCpf(cpf);
          if (!existeCPF) {
            await api
              .post('Usuario/save.php', {
                pdo: getClinica(),
                cpf: cpf,
                rg: rg,
                nome: nome,
                dataNasc: dataNasc,
                sexo: sexo,
                naturalidade: naturalidade,
                nacionalidade: nacionalidade,
                estadoCivil: estadoCivil,
                profissao: profissao,
                telefone: telefone,
                celular: celular,
                convenio: convenio,
                nCartao: nCartao,
                login: login,
                ativo: 0,
                senha: '',
                tipo: 0,
                cro: '',
                endCepR: cep,
                endLograR: logradouro,
                endNumR: numero,
                endBairroR: bairro,
                endCidadeR: cidade,
                endEstadoR: estado,
                endCompleR: complemento,
                endCepP: cep2,
                endLograP: logradouro2,
                endNumP: numero2,
                endBairroP: bairro2,
                endCidadeP: cidade2,
                endEstadoP: estado2,
                endCompleP: complemento2,
                comissao: null,
              })
              .then(res => {
                abrirAviso('success', 'Paciente cadastrado.');
                setPacientes([...pacientes, res.data]);
              });
          } else {
            abrirAviso('warning', 'CPF já cadastrado.');
          }
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
    setModalEditarCadastrar(false);
  };

  const editar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.pacAlt !== '0' && permissoes !== false) {
        let valorCPF = vCpf;
        if (vCpf !== cpf) {
          let existeCPF = await verificarCpf(cpf);
          if (!existeCPF) {
            valorCPF = cpf;
          } else {
            abrirAviso('warning', 'CPF já cadastrado.');
            setCpf(valorCPF);
          }
        }
        if (validarCampos()) {
          await api
            .post('Usuario/update.php', {
              pdo: getClinica(),
              cpf: valorCPF,
              rg: rg,
              nome: nome,
              dataNasc: dataNasc,
              sexo: sexo,
              naturalidade: naturalidade,
              nacionalidade: nacionalidade,
              estadoCivil: estadoCivil,
              profissao: profissao,
              telefone: telefone,
              celular: celular,
              convenio: convenio,
              nCartao: nCartao,
              login: login,
              endCepR: cep,
              endLograR: logradouro,
              endNumR: numero,
              endBairroR: bairro,
              endCidadeR: cidade,
              endEstadoR: estado,
              endCompleR: complemento,
              endCepP: cep2,
              endLograP: logradouro2,
              endNumP: numero2,
              endBairroP: bairro2,
              endCidadeP: cidade2,
              endEstadoP: estado2,
              endCompleP: complemento2,
              comissao: null,
              id: idPaciente,
            })
            .then(res => {
              abrirAviso('success', 'Salvo.');
              setPacientes(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
            })
            .catch(error =>
              console.log('Erro ao editar paciente. Erro: ' + error),
            );
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
    setModalEditarCadastrar(false);
  };

  const consultarCepR = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep)
      .then(res => {
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.cidade);
        setEstado(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const consultarCepP = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep2)
      .then(res => {
        setCep2(res.data.cep);
        setLogradouro2(res.data.logradouro);
        setBairro2(res.data.bairro);
        setCidade2(res.data.cidade);
        setEstado2(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  useEffect(() => {
    if (paciente) {
      abrirDados();
    }
  }, [modalEditarCadastrar]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modalEditarCadastrar}
        onClose={() => setModalEditarCadastrar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {paciente ? 'Dados do Paciente' : 'Cadastrar Paciente'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="CPF *"
                size="small"
                fullWidth
                value={cpf}
                onChange={e =>
                  setCpf(mask(unMask(e.target.value), ['99999999999']))
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Rg *"
                size="small"
                fullWidth
                value={rg}
                onChange={e => setRg(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nome *"
                size="small"
                fullWidth
                value={nome}
                onChange={e => setNome(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Nascimento *"
                size="small"
                type="date"
                fullWidth
                value={dataNasc}
                onChange={e => setDataNasc(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label="Sexo *"
                size="small"
                fullWidth
                value={sexo}
                onChange={e => setSexo(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="m">M</MenuItem>
                <MenuItem value="f">F</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Naturalidade"
                size="small"
                fullWidth
                value={naturalidade}
                onChange={e => setNaturalidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nacionalidade"
                size="small"
                fullWidth
                value={nacionalidade}
                onChange={e => setNacionalidade(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Estado civil"
                size="small"
                fullWidth
                value={estadoCivil}
                onChange={e => setEstadoCivil(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="1">Solteiro(a)</MenuItem>
                <MenuItem value="2">Casado(a)</MenuItem>
                <MenuItem value="3">Separado(a)</MenuItem>
                <MenuItem value="4">Divorciado(a)</MenuItem>
                <MenuItem value="5">Viúvo(a)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Profissão *"
                size="small"
                fullWidth
                value={profissao}
                onChange={e => setProfissao(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Telefone"
                size="small"
                fullWidth
                value={telefone}
                onChange={e =>
                  setTelefone(mask(unMask(e.target.value), ['(99)9999-9999']))
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Celular"
                size="small"
                fullWidth
                value={celular}
                onChange={e =>
                  setCelular(mask(unMask(e.target.value), ['(99)99999-9999']))
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-mail"
                size="small"
                fullWidth
                value={login}
                onChange={e => setLogin(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Convênio"
                size="small"
                fullWidth
                value={convenio}
                onChange={e => setConvenio(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="">Selecione</MenuItem>
                {convenios.map(conv => (
                  <MenuItem key={conv.id} value={conv.id}>
                    {conv.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nº Cartão"
                size="small"
                fullWidth
                value={nCartao}
                onChange={e => setNumeroCartao(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <h5 className={classes.titulos}>Endereço residencial</h5>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="cep">Cep</InputLabel>
                <OutlinedInput
                  id="cep"
                  type="text"
                  value={cep}
                  onChange={e =>
                    setCep(mask(unMask(e.target.value), ['99999999']))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => consultarCepR()} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Logradouro"
                size="small"
                fullWidth
                value={logradouro}
                onChange={e => setLogradouro(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Número"
                size="small"
                fullWidth
                value={numero}
                onChange={e => setNumero(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Bairro"
                size="small"
                fullWidth
                value={bairro}
                onChange={e => setBairro(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Cidade"
                size="small"
                fullWidth
                value={cidade}
                onChange={e => setCidade(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Estado"
                size="small"
                fullWidth
                value={estado}
                onChange={e => setEstado(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                size="small"
                fullWidth
                value={complemento}
                onChange={e => setComplemento(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <h5 className={classes.titulos}>Endereço profissional</h5>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="cep">Cep</InputLabel>
                <OutlinedInput
                  id="cep"
                  type="text"
                  value={cep2}
                  onChange={e =>
                    setCep2(mask(unMask(e.target.value), ['99999999']))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => consultarCepP()} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Logradouro"
                size="small"
                fullWidth
                value={logradouro2}
                onChange={e => setLogradouro2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Número"
                size="small"
                fullWidth
                value={numero2}
                onChange={e => setNumero2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Bairro"
                size="small"
                fullWidth
                value={bairro2}
                onChange={e => setBairro2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Cidade"
                size="small"
                fullWidth
                value={cidade2}
                onChange={e => setCidade2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Estado"
                size="small"
                fullWidth
                value={estado2}
                onChange={e => setEstado2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complemento"
                size="small"
                fullWidth
                value={complemento2}
                onChange={e => setComplemento2(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={salvar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button
            autoFocus
            onClick={() => setModalEditarCadastrar(false)}
            variant="contained"
            color="default"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditarCadastrarPaciente;
