import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formatData, formatReal } from '../../../utils/funcoes';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  MenuItem,
  Paper,
  CircularProgress,
  Avatar,
  Tooltip,
  Zoom,
  Fab,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import EditarCadastrarContaPagar from './EditarCadastrar';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';
import { useNotification } from '../../../hooks/notificacao';

const ContasPagar = () => {
  const { abrirAviso } = useNotification();
  const [loading, setLoading] = useState(true);
  const [contas, setContas] = useState([]);
  const [totalPagar, setTotalPagar] = useState(0);
  const [totalPago, setTotalPago] = useState(0);
  const [buscar, setBuscar] = useState('');
  const [filtro, setFiltro] = useState('');
  const [status, setStatus] = useState('status=0');
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const classes = useStyles();
  const [conta, setConta] = useState(null);
  const [modalCadastrarEditar, setModalCadastrarEditar] = useState(false);

  const listarContas = async consulta => {
    setContas([]);
    await api
      .post('Pagar/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        if (res.data.length > 0) {
          setContas(res.data);
        }
      })
      .catch(error => console.log(error));
  };

  const totalAPagar = contas => {
    let totalPago = contas
      .filter(c => c.status === '1')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    let totalPagar = contas
      .filter(c => c.status === '0')
      .reduce((sum, li) => sum + Number(li.valor), 0);

    setTotalPagar(totalPagar);
    setTotalPago(totalPago);
  };

  const abrirConta = conta => {
    setConta(conta);
    setModalCadastrarEditar(true);
  };

  const abrirCadastro = () => {
    setConta(null);
    setModalCadastrarEditar(true);
  };

  const situacaoConta = valor => {
    if (valor === '0') {
      return (
        <Tooltip title="CONTA PENDENTE" TransitionComponent={Zoom}>
          <Avatar className={classes.amarelo}>PE</Avatar>
        </Tooltip>
      );
    }
    if (valor === '1') {
      return (
        <Tooltip title="CONTA PAGA" TransitionComponent={Zoom}>
          <Avatar className={classes.verde}>PG</Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="CONTA CANCELADA" TransitionComponent={Zoom}>
          <Avatar className={classes.vermelho}>CA</Avatar>
        </Tooltip>
      );
    }
  };

  const atualizar = async () => {
    await listarContas('AND status=0 ORDER BY vencimento');
    setLoading(false);
  };

  const filtrar = async () => {
    setLoading(true);
    let filtroBusca = '';
    if (status !== '3') {
      filtroBusca += ` AND ${status}`;
    }

    if (filtro) {
      filtroBusca += ` AND ${filtro}`;
    }

    if (dataInicio && dataFim && filtro) {
      filtroBusca += ` AND ${filtro} BETWEEN '${dataInicio}' AND '${dataFim}'`;
    } else {
      filtroBusca += ' ORDER BY vencimento ASC';
    }
    await listarContas(filtroBusca);
    setLoading(false);
  };

  const ehContaVencida = (situacao, e) => {
    let vencimento = e.split('-');
    let data = new Date(vencimento[0], vencimento[1] - 1, vencimento[2]);
    let hoje = new Date();
    if (data < hoje && situacao === '0') {
      return classes.contaVencida;
    }
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    totalAPagar([...contas]);
  }, [contas]);

  const filtradoPorDescricao = contas.filter(conta => {
    return conta.descricao.toLowerCase().indexOf(buscar.toLowerCase()) !== -1;
  });

  return (
    <>
      <Menu titulo="Contas a Pagar" />
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Pesquisa Avançada
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={buscar}
                    onChange={e => setBuscar(e.target.value)}
                    size="small"
                    label="Pesquisar"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Status"
                    value={status}
                    onChange={e => setStatus(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="3">Todas</MenuItem>
                    <MenuItem value="status=0">Aberto</MenuItem>
                    <MenuItem value="status=1">Pago</MenuItem>
                    <MenuItem value="status=-1">Cancelado</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Filtro por"
                    value={filtro}
                    onChange={e => setFiltro(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    <MenuItem value="emissao">Emissão</MenuItem>
                    <MenuItem value="vencimento">Vencimento</MenuItem>
                    <MenuItem value="pagoDia">Pagamento</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    size="small"
                    label="Periodo Inicial"
                    value={dataInicio}
                    onChange={e => setDataInicio(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    size="small"
                    label="Periodo Final"
                    value={dataFim}
                    onChange={e => setDataFim(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Button
                  fullWidth
                  onClick={filtrar}
                  variant="contained"
                  color="secondary"
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '200px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.rootGrid}>
          <Box display="flex" p={0} bgcolor="background.paper">
            <Box p={1} width="100%" className={classes.totalizadores}>
              <b>
                <ArrowUpwardIcon color="error" /> {formatReal(totalPagar)}
              </b>
            </Box>
            <Box p={1} width="100%" className={classes.totalizadores}>
              <b>
                <CheckCircleIcon color="primary" /> {formatReal(totalPago)}
              </b>
            </Box>
          </Box>
          {filtradoPorDescricao.map(conta => (
            <Paper
              className={classes.paper}
              key={conta.id}
              onClick={() => abrirConta(conta)}
            >
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                className={ehContaVencida(conta.status, conta.vencimento)}
              >
                <Grid item>{situacaoConta(conta.status)}</Grid>
                <Grid item xs>
                  <Typography className={classes.faturaHead}>
                    <b>Descrição:</b> {conta.descricao}
                  </Typography>
                  <Typography className={classes.faturaBody}>
                    <div>
                      <b>Vencimento:</b> {formatData(conta.vencimento)}
                    </div>
                    <div>
                      <b>Valor:</b> {formatReal(conta.valor)}
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      )}

      {modalCadastrarEditar && (
        <EditarCadastrarContaPagar
          modalCadastrarEditar={modalCadastrarEditar}
          setModalCadastrarEditar={setModalCadastrarEditar}
          conta={conta}
          abrirAviso={abrirAviso}
          contas={contas}
          setContas={setContas}
        />
      )}
      <Fab className={classes.fab} color="primary" onClick={abrirCadastro}>
        <AddIcon />
      </Fab>
    </>
  );
};
export default ContasPagar;
