import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
        zIndex:10
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    select: {
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(1),

      },
}));