import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { createContext, useCallback, useContext, useState } from 'react';

const NotificacoesContext = createContext({});

export const NotificacoesProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');

  const abrirAviso = useCallback((type, description) => {
    setOpen(true);
    setTipoAviso(type);
    setMensagemAviso(description);
  }, []);

  const close = () => {
    setOpen(false);
    setTipoAviso('');
    setMensagemAviso('');
  };

  return (
    <NotificacoesContext.Provider value={{ abrirAviso }}>
      {children}
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={4000}
          onClose={() => close()}
        >
          <Alert variant="filled" onClose={close} severity={tipoAviso}>
            {mensagemAviso}
          </Alert>
        </Snackbar>
      )}
    </NotificacoesContext.Provider>
  );
};

export function useNotification() {
  const context = useContext(NotificacoesContext);
  if (!context) {
    throw new Error('Deve ser utilizado em um NotificacoesContext');
  }
  return context;
}
