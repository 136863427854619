import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(8),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  rootGrid: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  faturaHead: {
    fontSize: '13px',
  },
  faturaBody: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '11px',
  },
  paper: {
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#EEEDED',
    },
    cursor: 'pointer',
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
  contaVencida: {
    backgroundColor: '#f8beb7',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  totalizadores: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
