import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from './services/auth.js';
import { PermissoesProvider } from './hooks/permissoes.js';
import { ConfigProvider } from './hooks/config.js';
import Login from './pages/Login';
import Agenda from './pages/Agenda';
import Pacientes from './pages/Pacientes';
import Configuracoes from './pages/Configuracoes';
import MeusDados from './pages/MeusDados';
import Movimentacao from './pages/Financeiro/Movimentacao';
import ContasPagar from './pages/Financeiro/Pagar';
import ContasReceber from './pages/Financeiro/Receber';
import { NotificacoesProvider } from './hooks/notificacao.jsx';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <NotificacoesProvider>
      <Switch>
        <Route exact path="/" component={Login} />
        <PermissoesProvider>
          <ConfigProvider>
            <PrivateRoute path="/app" component={Agenda} />
            <PrivateRoute path="/pacientes" component={Pacientes} />
            <PrivateRoute path="/movimentacao" component={Movimentacao} />
            <PrivateRoute path="/contaspagar" component={ContasPagar} />
            <PrivateRoute path="/contasreceber" component={ContasReceber} />
            <PrivateRoute path="/configuracoes" component={Configuracoes} />
            <PrivateRoute path="/meusdados" component={MeusDados} />
          </ConfigProvider>
        </PermissoesProvider>
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </NotificacoesProvider>
  </BrowserRouter>
);

export default Routes;
