import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  MenuItem,
  TextField,
} from '@material-ui/core';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { useStyles } from './styles';

const InserirEvento = ({
  configuracoes,
  permissoes,
  eventos,
  setEventos,
  validarCampos,
  idCliente,
  setIdCliente,
  idDentista,
  setIdDentista,
  dataInicio,
  setDataIncio,
  horaInicio,
  setHoraInicio,
  horaFim,
  setHoraFim,
  obsEvento,
  setObsEvento,
  presenca,
  ModalCadastro,
  setAbrirModalCadastro,
  abrirAviso,
  nomeCliente,
  clientes,
  dentistas,
  descFeriado,
  setDescricaoFeriado,
  feriados,
}) => {
  const [desativar, setDesativar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const cadastrarEvento = async () => {
    setDesativar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenCad !== '0' && permissoes !== false) {
        if (!existeFeriado(dataInicio)) {
          if (validarCampos()) {
            let titulo = '';
            if (idCliente === '1') {
              titulo = 'S/C: ' + obsEvento;
            } else {
              titulo = nomeCliente(idCliente);
            }
            await api
              .post('Agenda/save.php', {
                pdo: getClinica(),
                title: titulo,
                color: presenca,
                start: dataInicio + ' ' + horaInicio,
                end: dataInicio + ' ' + horaFim,
                idCli: idCliente,
                idDentis: idDentista,
                observacao: obsEvento,
                idTratamento: null,
              })
              .then(res => {
                setEventos([...eventos, res.data]);
                abrirAviso('success', 'Evento Cadastrado.');
                setAbrirModalCadastro(false);
              })
              .catch(error => console.log(error));
          } else {
            abrirAviso('warning', 'Preencha os campos obrigatórios.');
          }
        } else {
          abrirAviso('info', 'Feriado: ' + descFeriado);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesativar(false);
  };

  const existeFeriado = data => {
    let ano = new Date();
    for (let i = 0; i < feriados.length; i++) {
      if (
        data ===
        ano.getFullYear() + '-' + feriados[i].mes + '-' + feriados[i].dia
      ) {
        setDescricaoFeriado(feriados[i].descricao);
        return true;
      }
    }
    return false;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={ModalCadastro}
      onClose={() => setAbrirModalCadastro(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Cadastrar Agendamento'}
      </DialogTitle>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Paciente"
                className={classes.select}
                value={idCliente}
                onChange={e => setIdCliente(e.target.value)}
                variant="outlined"
                autoFocus
              >
                <MenuItem value="1">Cliente sem cadastro</MenuItem>
                {clientes.map(cliente => (
                  <MenuItem key={cliente.id} value={cliente.id}>
                    {cliente.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Dentista"
                className={classes.select}
                value={idDentista}
                onChange={e => setIdDentista(e.target.value)}
                variant="outlined"
                autoFocus
              >
                {dentistas.map(dentista => (
                  <MenuItem key={dentista.id} value={dentista.id}>
                    {dentista.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Data do Agendamento"
                fullWidth
                type="date"
                onChange={e => setDataIncio(e.target.value)}
                value={dataInicio}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Hora inicio"
                className={classes.textField}
                fullWidth
                value={horaInicio}
                onChange={e => setHoraInicio(e.target.value)}
                type="time"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Hora fim"
                className={classes.textField}
                fullWidth
                value={horaFim}
                type="time"
                onChange={e => setHoraFim(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observação"
                fullWidth
                margin="normal"
                value={obsEvento}
                onChange={e => setObsEvento(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cadastrarEvento}
          variant="contained"
          color="primary"
          autoFocus
          disabled={desativar}
        >
          Cadastrar
        </Button>
        <Button
          autoFocus
          onClick={() => setAbrirModalCadastro(false)}
          variant="contained"
          color="default"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InserirEvento;
