import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  MenuItem,
  TextField,
} from '@material-ui/core';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { useStyles } from './styles';

const VisualizarEvento = ({
  configuracoes,
  permissoes,
  abrirAviso,
  nomeCliente,
  validarCampos,
  abrirModal,
  setAbrirModal,
  idCliente,
  setIdCliente,
  desativar,
  idTratamento,
  title,
  idEvento,
  setEventos,
  idDentista,
  setIdDentista,
  dataInicio,
  dataFim,
  horaInicio,
  setHoraInicio,
  horaFim,
  setHoraFim,
  obsEvento,
  setObsEvento,
  presenca,
  setPresenca,
  clientes,
  dentistas,
}) => {
  const [ModalConfirmExclusao, setModalConfirmExclusao] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const alterarEvento = async () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenAlt !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let titulo = null;
          if (idCliente === '1') {
            titulo = 'S/C: ' + obsEvento;
          } else {
            if (idTratamento) {
              titulo = title;
            } else {
              titulo = nomeCliente(idCliente);
            }
          }

          await api
            .post('Agenda/update.php', {
              pdo: getClinica(),
              title: titulo,
              color: presenca,
              start: dataInicio + ' ' + horaInicio,
              end: dataFim + ' ' + horaFim,
              idCli: idCliente,
              idDentis: idDentista,
              observacao: obsEvento,
              id: idEvento,
            })
            .then(res => {
              abrirAviso('info', 'Agendamento atualizado.');
              setAbrirModal(false);
              setEventos(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  const excluirEvento = () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenDel !== '0' && permissoes !== false) {
        api
          .post('Agenda/remove.php?id=' + idEvento + '&pdo=' + getClinica())
          .then(() => {
            abrirAviso('info', 'Agendamento removido.');
            setEventos(eventos => eventos.filter(e => e.id !== idEvento));
            setModalConfirmExclusao(false);
            setAbrirModal(false);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={abrirModal}
        onClose={() => setAbrirModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{'Agendamento'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Agendamento do paciente</DialogContentText>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Paciente"
                  className={classes.select}
                  value={idCliente}
                  onChange={e => setIdCliente(e.target.value)}
                  variant="outlined"
                  autoFocus
                  disabled={desativar}
                >
                  <MenuItem value="1">Cliente sem cadastro</MenuItem>
                  {clientes.map(cliente => (
                    <MenuItem key={cliente.id} value={cliente.id}>
                      {cliente.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Dentista"
                  className={classes.select}
                  value={idDentista}
                  onChange={e => setIdDentista(e.target.value)}
                  variant="outlined"
                  autoFocus
                >
                  {dentistas.map(dentista => (
                    <MenuItem key={dentista.id} value={dentista.id}>
                      {dentista.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora inicio"
                  fullWidth
                  value={horaInicio}
                  onChange={e => setHoraInicio(e.target.value)}
                  type="time"
                  defaultValue="07:30"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora fim"
                  fullWidth
                  value={horaFim}
                  type="time"
                  onChange={e => setHoraFim(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observação"
                  fullWidth
                  margin="normal"
                  value={obsEvento}
                  onChange={e => setObsEvento(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Presença"
                  fullWidth
                  value={presenca}
                  onChange={e => setPresenca(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="0">Aguardando</MenuItem>
                  <MenuItem value="#28a745">Confirmada</MenuItem>
                  <MenuItem value="#ff8c00">Desmarcada</MenuItem>
                  <MenuItem value="#daa520">Cliente Desmarcou</MenuItem>
                  <MenuItem value="#ff0000">Falta</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalConfirmExclusao(true)}
            variant="contained"
            color="secondary"
          >
            Excluir
          </Button>
          <Button onClick={alterarEvento} variant="contained" color="primary">
            Alterar
          </Button>
          <Button
            onClick={() => setAbrirModal(false)}
            variant="contained"
            color="default"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmacao de remocao de agendamento */}
      <Dialog
        fullScreen={fullScreen}
        open={ModalConfirmExclusao}
        onClose={() => setModalConfirmExclusao(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Excluir Agendamento'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            Tem certeza que deseja excluir o agendamento? Após executar esta
            ação não será possível recuperar o agendamento removido.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={excluirEvento}
            variant="contained"
            color="primary"
            autoFocus
          >
            Sim
          </Button>
          <Button
            autoFocus
            onClick={() => setModalConfirmExclusao(false)}
            variant="contained"
            color="secondary"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default VisualizarEvento;
