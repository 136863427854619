import React, { useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Avatar,
  Container,
} from '@material-ui/core';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import { login, login_clinica, makeid } from '../../services/auth';
import { useStyles } from './styles';
import { useNotification } from '../../hooks/notificacao';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sigeclin.com.br/">
        Sigeclin Sistemas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const { abrirAviso } = useNotification();
  const [clinica, setClinica] = useState('');
  const [user, setUser] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrar, setLembrar] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem('Crd')) {
      let dados = localStorage.getItem('Crd');
      let decode = JSON.parse(atob(dados.substr(19, dados.length)));
      setClinica(decode.clinica);
      setUser(decode.login);
      setSenha(atob(decode.senha));
    }
  }, []);

  const validarCampos = () => {
    if (clinica !== '' && user !== '' && senha !== '') {
      return true;
    }
    return false;
  };

  const logar = e => {
    e.preventDefault();
    if (validarCampos()) {
      api
        .post('Usuario/validate.php', {
          pdo: clinica,
          login: user,
          senha: senha,
        })
        .then(res => {
          if (res.data.id !== undefined) {
            if (lembrar) {
              localStorage.removeItem('Crd');
              let credencial = {
                clinica: clinica,
                login: user,
                senha: btoa(senha),
              };
              let dados = JSON.stringify(credencial);
              let encode = btoa(dados);
              localStorage.setItem('Crd', makeid() + encode);
            }
            login(res.data.id);
            login_clinica(clinica);
            window.location.pathname = '/app';
          } else {
            abrirAviso('error', 'Dados de autenticação inválidos');
          }
        })
        .catch(error => console.log(error));
    } else {
      abrirAviso('warning', 'preencha todos os campos');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar alt="SigeOdonto" src={logo} className={classes.large} />
        <Typography component="h1" variant="h5">
          AppSigeOdonto
        </Typography>
        <form className={classes.form} onSubmit={logar}>
          <TextField
            variant="outlined"
            margin="normal"
            value={clinica}
            onChange={e => setClinica(e.target.value)}
            fullWidth
            label="Clínica"
            name="Clinica"
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={user}
            onChange={e => setUser(e.target.value)}
            fullWidth
            label="Login"
            name="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={senha}
            onChange={e => setSenha(e.target.value)}
            fullWidth
            label="Senha"
            type="password"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={lembrar}
                color="primary"
                onChange={() => setLembrar(!lembrar)}
              />
            }
            label="Lembrar"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default Login;
