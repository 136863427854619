import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  saldo: {
    margin: '10px 0px',
  },
});
