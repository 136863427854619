import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  buttonPagar: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#3b8e3e',
    },
    color: '#FFFF',
  },
}));
