import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { getClinica, getToken } from '../../../../services/auth';
import { usePermissoes } from '../../../../hooks/permissoes';
import { useConfig } from '../../../../hooks/config';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PagarConta from '../PagarConta';
import EstornarCancelarContaPagar from '../EstornarCancelar';
import { useStyles } from './styles';

const EditarCadastrarContaPagar = ({
  conta,
  modalCadastrarEditar,
  setModalCadastrarEditar,
  abrirAviso,
  contas,
  setContas,
}) => {
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const [desabilitar, setDesabilitar] = useState(false);
  const [idConta, setIdConta] = useState(null);
  const [emissao, setEmissao] = useState(null);
  const [vencimento, setVencimento] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState(null);
  const [obs, setObs] = useState(null);
  const [pagoDia, setPagoDia] = useState(null);
  const [formaPag, setFormaPag] = useState('');
  const [qtdVezes, setQtdVezes] = useState(1);
  const [repetirLancamento, setRapatirLancamento] = useState('False');
  const [statusConta, setStatusConta] = useState('');
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalPagar, setModalPagar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const salvar = () => {
    if (idConta) {
      alterarConta();
    } else {
      cadastrarConta();
    }
  };

  const validacao = () => {
    if (descricao && valor && emissao && vencimento) {
      return true;
    }
    return false;
  };

  const atualizar = () => {
    if (conta) {
      setIdConta(conta.id);
      setEmissao(conta.emissao);
      setVencimento(conta.vencimento);
      setDescricao(conta.descricao);
      setValor(conta.valor);
      setObs(conta.observacao);
      setPagoDia(conta.pagoDia);
      setStatusConta(conta.status);
    }
  };

  const cadastrarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finCad !== '0' && permissoes !== false) {
        if (validacao()) {
          if (qtdVezes > 0) {
            await api
              .post('Pagar/save.php', {
                pdo: getClinica(),
                descricao: descricao,
                valor: valor,
                emissao: emissao,
                vencimento: vencimento,
                status: 0,
                observacao: obs,
                userCadastro: getToken(),
                qtdVezes: qtdVezes,
                repetirLancamento: repetirLancamento,
              })
              .then(res => {
                setContas([...contas, res.data]);
                abrirAviso('success', 'Conta cadastrada');
                setModalCadastrarEditar(false);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            abrirAviso(
              'warning',
              'Quantidade de vezes deverá ser maior que zero.',
            );
          }
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const alterarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (validacao()) {
          await api
            .post('Pagar/update.php', {
              pdo: getClinica(),
              descricao: descricao,
              valor: valor,
              emissao: emissao,
              vencimento: vencimento,
              status: 0,
              observacao: obs,
              userUltAlteracao: getToken(),
              id: idConta,
            })
            .then(res => {
              setContas(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('info', 'Conta alterada');
              setModalCadastrarEditar(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const pagarConta = async e => {
    setDesabilitar(true);
    e.preventDefault();
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (pagoDia !== '' && formaPag !== '') {
          await api
            .post('Pagar/pay.php', {
              pdo: getClinica(),
              status: 1,
              dataPagamento: pagoDia,
              idUserBaixa: getToken(),
              userUltAlteracao: getToken(),
              formaPag: formaPag,
              id: idConta,
            })
            .then(res => {
              setContas(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('success', 'Conta paga');
              setModalPagar(false);
              setModalCadastrarEditar(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const desativarAtivarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (
        permissoes.finDes !== '0' &&
        permissoes.finAlt !== '0' &&
        permissoes !== false
      ) {
        let acao = 0;
        if (statusConta === '0') {
          acao = -1;
        }
        await api
          .post('Pagar/chargeBack.php', {
            pdo: getClinica(),
            status: acao,
            userUltAlteracao: getToken(),
            id: idConta,
          })
          .then(res => {
            abrirAviso('info', 'Conta alterada.');
            setContas(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
            setModalCancelar(false);
            setModalCadastrarEditar(false);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    atualizar();
  }, [modalCadastrarEditar]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modalCadastrarEditar}
        onClose={() => setModalCadastrarEditar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {idConta ? `Conta Nº ${idConta}` : 'Cadastrar Conta'}
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desabilitar}
                  label="Descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desabilitar}
                  type="date"
                  label="Emissão"
                  value={emissao}
                  onChange={e => setEmissao(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desabilitar}
                  type="date"
                  label="Vencimento"
                  value={vencimento}
                  onChange={e => setVencimento(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desabilitar}
                  type="number"
                  label="Valor"
                  value={valor}
                  onChange={e => setValor(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {!idConta && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={desabilitar}
                    type="number"
                    label="Qtd. Vezes"
                    value={qtdVezes}
                    onChange={e => setQtdVezes(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              )}
              {!idConta && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={desabilitar}
                    select
                    label="Repetir Lançamento"
                    value={repetirLancamento}
                    onChange={e => setRapatirLancamento(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="False" selected>
                      Não
                    </MenuItem>
                    <MenuItem value="True">Sim</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desabilitar}
                  label="Observação"
                  value={obs}
                  onChange={e => setObs(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {idConta && statusConta === '1' && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={desabilitar}
                    type="date"
                    label="Data Pagamento"
                    value={pagoDia}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {statusConta === '0' && idConta && (
            <Button
              onClick={setModalCancelar}
              variant="contained"
              title="Cancelar conta"
              color="secondary"
            >
              <DeleteIcon />
            </Button>
          )}
          {statusConta === '1' && idConta && (
            <Button
              onClick={setModalCancelar}
              variant="contained"
              title="Estornar conta"
              color="default"
            >
              Estornar
            </Button>
          )}
          {statusConta === '0' && idConta && (
            <Button
              onClick={() => setModalPagar(true)}
              className={classes.buttonPagar}
              variant="contained"
            >
              Pagar
            </Button>
          )}
          {statusConta !== '-1' && statusConta !== '1' && (
            <Button
              onClick={salvar}
              variant="contained"
              color="primary"
              disabled={desabilitar}
            >
              Salvar
            </Button>
          )}
          <Button
            variant="contained"
            color="default"
            onClick={() => setModalCadastrarEditar(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {modalPagar && (
        <PagarConta
          modalPagar={modalPagar}
          setModalPagar={setModalPagar}
          idConta={idConta}
          pagoDia={pagoDia}
          setPagoDia={setPagoDia}
          formaPag={formaPag}
          setFormaPag={setFormaPag}
          pagarConta={pagarConta}
          fullScreen={fullScreen}
          desabilitar={desabilitar}
        />
      )}

      {modalCancelar && (
        <EstornarCancelarContaPagar
          modalCancelar={modalCancelar}
          setModalCancelar={setModalCancelar}
          statusConta={statusConta}
          idConta={idConta}
          desativarAtivarConta={desativarAtivarConta}
          desabilitar={desabilitar}
          fullScreen={fullScreen}
        />
      )}
    </>
  );
};
export default EditarCadastrarContaPagar;
